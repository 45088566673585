<template>
  <div id="details">
    <div class="box">
      <div class="box-img" style="overflow: hidden">
        <img
          :src="'https://gxzw.linjiaxiaoda.com/picture/prod-api' + list.cover"
          style="width: 100%"
        />
      </div>
      <!-- 标题 -->
      <div class="box-title flex ALcenter">
        <div class="shous fw6 fs16" @click="gohome">新闻动态</div>
        <div class="box-title-icon"></div>
        <div class="hui999 fs16">{{ list.title }}</div>
      </div>
      <div class="tac fs20 fw6" style="margin-top: 60px">{{ list.title }}</div>
      <div
        class="tac fs18 hui666"
        style="margin-top: 14px; margin-bottom: 50px"
      >
        {{ list.createTime }}
      </div>
      <div v-html="list.content" class="tac box-body fs16"></div>
      <div class="flex ALcenter box-next shous" @click="goxia">
        <template v-if="nextId !== ''">
          <div>下一篇：</div>
          <div>【</div>
          <div>{{ nextList.title }}</div>
          <div>】</div>
        </template>
        <template v-else>
          <div>已经是最后一篇文章了...</div>
        </template>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
import { getAllInfo } from "@/api/new";
export default {
  name: "Details",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      id: "",
      list: {},
      nextId: "",
      nextList: {},
    };
  },
  mounted() {
    console.log("进入了");
    this.getList(this.$route.query.id);
  },
  methods: {
    getList(e) {
      let obj = {
        id: e,
      };
      getAllInfo(obj).then((res) => {
        this.list = res.data;
        if (res.data.nextId !== null) {
          this.nextId = res.data.nextId;
          this.getNext(this.nextId);
        } else {
          this.nextId = "";
        }
      });
    },
    getNext(e) {
      let obj = {
        id: e,
      };
      getAllInfo(obj).then((res) => {
        this.nextList = res.data;
      });
    },
    goxia() {
      if (this.nextId !== "") {
        let gg = this.$router.resolve({
          path: "/news/details",
          query: { id: this.nextId },
        });
        window.open(gg.href, "_blank");
        // this.getList(this.nextId);
      }
    },
    gohome() {
      this.$router.push("/news");
    },
  },
};
</script>

<style scoped lang="less">
#details {
  .box {
    width: 1200px;
    margin: 0 360px;
    .box-img {
      width: 1200px;
      height: 576px;
    }
    .box-title {
      width: 1200px;
      height: 80px;
      .box-title-icon {
        width: 7px;
        height: 11px;
        margin: 0 20px;
        background-image: url(~@/assets/heiyou.png);
        background-size: 100% 100%;
      }
    }
    .box-body {
      width: 1200px;
    }

    .box-next {
      width: 1200px;
      margin: 200px 0 104px;
    }
  }
}
</style>